<template>
  <b-row>
    <b-col cols="12" md="4" lg="4">
      <router-link to="/attendances">
        <h4 class="text-primary font-weight-bolder">Attendance</h4>
        <statistic-card-horizontal
          id="dashboard-statistic"
          icon="UsersIcon"
          :statistic="
            attendance.presentAttendance + '/' + attendance.totalAttendance
          "
          :statistic-title="
            'Attendance ' + moment(Date()).format('dddd, MMMM Do YYYY')
          "
        />
      </router-link>
    </b-col>
    <b-col
      cols="12"
      md="4"
      lg="4"
      :class="{
        'd-none': checkUser(),
      }"
    >
      <router-link to="/overtimes">
        <h4 class="text-primary font-weight-bolder">Overtime</h4>
        <statistic-card-horizontal
          id="dashboard-statistic"
          icon="ClipboardIcon"
          :statistic="overtime.waiting"
          statistic-title="Waiting"
        />
      </router-link>
    </b-col>
    <b-col
      cols="12"
      md="4"
      lg="4"
      :class="{
        'd-none': checkUser(),
      }"
    >
      <router-link to="/permits">
        <h4 class="text-primary font-weight-bolder">Permit</h4>
        <statistic-card-horizontal
          id="dashboard-statistic"
          icon="CalendarIcon"
          :statistic="permit.waiting"
          :statistic-title="'Waiting'"
        />
      </router-link>
    </b-col>
    <b-col
      cols="12"
      md="4"
      lg="4"
      :class="{
        'd-none': checkUser(),
      }"
    >
      <router-link to="/reimbursements">
        <h4 class="text-primary font-weight-bolder">Reimbursement</h4>
        <statistic-card-horizontal
          id="dashboard-statistic"
          icon="CalendarIcon"
          :statistic="reimbursement.waiting"
          :statistic-title="'Waiting'"
        />
      </router-link>
    </b-col>
    <b-col
      cols="12"
      md="4"
      lg="4"
      :class="{
        'd-none': checkUser(),
      }"
    >
      <router-link to="/cash-advance">
        <h4 class="text-primary font-weight-bolder">Cash Advance</h4>
        <statistic-card-horizontal
          id="dashboard-statistic"
          icon="CalendarIcon"
          :statistic="cash_advance.waiting"
          :statistic-title="'Waiting'"
        />
      </router-link>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BLink } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import moment from "moment";

export default {
  components: {
    StatisticCardHorizontal,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
  },
  data() {
    return {
      attendance: {
        presentAttendance: 0,
        totalAttendance: 0,
      },
      overtime: {
        waiting: 0,
      },
      permit: {
        waiting: 0,
      },
      reimbursement: {
        waiting: 0,
      },
      cash_advance: {
        waiting: 0,
      },
    };
  },
  created() {
    this.checkPermission();
  },
  methods: {
    moment: function () {
      return moment();
    },
    checkPermission() {
      var $id = sessionStorage.getItem("employeeID");
      if ($id == 29 || $id == 30) {
        this.getAttendanceStatistic();
      } else {
        this.getAttendanceStatistic();
        this.getOvertimeStatistic();
        this.getPermitStatistic();
        this.getReimbursementStatistic();
        this.getCashAdvanceStatistic();
      }
    },
    checkUser() {
      var $id = sessionStorage.getItem("employeeID");
      if ($id == 29 || $id == 30) {
        return true;
      }
    },
    getAttendanceStatistic() {
      this.$http.get("attendances/statistic").then((response) => {
        // console.log(response.data.data)
        this.attendance.presentAttendance = response.data.data.present;
        this.attendance.totalAttendance = response.data.data.total;
      });
    },
    getOvertimeStatistic() {
      this.$http.get("overtimes/statistic").then((response) => {
        // console.log(response.data.data)
        this.overtime.waiting = response.data.data.waiting;
      });
    },
    getPermitStatistic() {
      this.$http.get("permits/statistic").then((response) => {
        // console.log(response.data.data)
        this.permit.waiting = response.data.data.waiting;
      });
    },
    getReimbursementStatistic() {
      this.$http.get("reimbursements/statistic").then((response) => {
        // console.log(response.data.data)
        this.reimbursement.waiting = response.data.data.waiting;
      });
    },
    getCashAdvanceStatistic() {
      this.$http.get("cash-advance/statistic").then((response) => {
        // console.log(response.data.data)
        this.cash_advance.waiting = response.data.data.waiting;
      });
    },
  },
};
</script>

<style>
div.card#dashboard-statistic {
  border-left: 5px solid #2296d4;
}

div.card#dashboard-statistic:hover {
  border-left: 10px solid #2296d4;
}
</style>
