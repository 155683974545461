var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('router-link',{attrs:{"to":"/attendances"}},[_c('h4',{staticClass:"text-primary font-weight-bolder"},[_vm._v("Attendance")]),_c('statistic-card-horizontal',{attrs:{"id":"dashboard-statistic","icon":"UsersIcon","statistic":_vm.attendance.presentAttendance + '/' + _vm.attendance.totalAttendance,"statistic-title":'Attendance ' + _vm.moment(Date()).format('dddd, MMMM Do YYYY')}})],1)],1),_c('b-col',{class:{
      'd-none': _vm.checkUser(),
    },attrs:{"cols":"12","md":"4","lg":"4"}},[_c('router-link',{attrs:{"to":"/overtimes"}},[_c('h4',{staticClass:"text-primary font-weight-bolder"},[_vm._v("Overtime")]),_c('statistic-card-horizontal',{attrs:{"id":"dashboard-statistic","icon":"ClipboardIcon","statistic":_vm.overtime.waiting,"statistic-title":"Waiting"}})],1)],1),_c('b-col',{class:{
      'd-none': _vm.checkUser(),
    },attrs:{"cols":"12","md":"4","lg":"4"}},[_c('router-link',{attrs:{"to":"/permits"}},[_c('h4',{staticClass:"text-primary font-weight-bolder"},[_vm._v("Permit")]),_c('statistic-card-horizontal',{attrs:{"id":"dashboard-statistic","icon":"CalendarIcon","statistic":_vm.permit.waiting,"statistic-title":'Waiting'}})],1)],1),_c('b-col',{class:{
      'd-none': _vm.checkUser(),
    },attrs:{"cols":"12","md":"4","lg":"4"}},[_c('router-link',{attrs:{"to":"/reimbursements"}},[_c('h4',{staticClass:"text-primary font-weight-bolder"},[_vm._v("Reimbursement")]),_c('statistic-card-horizontal',{attrs:{"id":"dashboard-statistic","icon":"CalendarIcon","statistic":_vm.reimbursement.waiting,"statistic-title":'Waiting'}})],1)],1),_c('b-col',{class:{
      'd-none': _vm.checkUser(),
    },attrs:{"cols":"12","md":"4","lg":"4"}},[_c('router-link',{attrs:{"to":"/cash-advance"}},[_c('h4',{staticClass:"text-primary font-weight-bolder"},[_vm._v("Cash Advance")]),_c('statistic-card-horizontal',{attrs:{"id":"dashboard-statistic","icon":"CalendarIcon","statistic":_vm.cash_advance.waiting,"statistic-title":'Waiting'}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }